/**
 * OpenToken for Node.JS
 * http://tools.ietf.org/html/draft-smith-opentoken-02
 *
 * ciphersuites.js
 * Ciphers for OpenToken.
 */
 var crypto = require('crypto');
 var Buffer = require('buffer/').Buffer;


 /**
  * Cipher Suites supported by OpenToken specification
  * The order of these is important!
  */
 var ciphers = [
   {
     id: 0,
     name: "Null",
     cipher: null,
     keysize: 0,
     mode: null,
     padding: null,
     ivlength: 0
   },
   {
     id: 1,
     name: "aes-256-cbc",
     cipher: "AES",
     keysize: 256,
     mode: "CBC",
     padding: "PKCS 5",
     ivlength: 16
   },
   {
     id: 2,
     name: "aes-128-cbc",
     cipher: "AES",
     keysize: 128,
     mode: "CBC",
     padding: "PKCS 5",
     ivlength: 16
   },
   {
     id: 3,
     name: "3des",
     cipher: "3DES",
     keysize: 168,
     mode: "CBC",
     padding: "PKCS 5",
     ivlength:8 
   }
 ];
 
 function generateKey(password, salt, cipherId) {
   salt = salt || Buffer.from([0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0, 0x0]);
   if (!cipherId || cipherId < 0 || cipherId >= ciphers.length) {
     return null;
   }
   var cipher = ciphers[cipherId];
   var iterations = 1000;
   var derivedKey = crypto.pbkdf2Sync(password, salt, iterations, cipher.keysize/8, 'sha1');
   return derivedKey;
 }
 
 
 exports.ciphers = ciphers;
 exports.generateKey  = generateKey;