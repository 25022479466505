import React from "react";
import * as AuthProxy from "./security/AuthProxy";
import './css/Form.css';

type LoginProps = {
  urls: Array<string>;
};
export default class Form extends React.Component<LoginProps> {
  
  private selectedURL:string="";
  componentDidMount() { 
    window.process = { ...window.process }
}

handleSubmit= (e: any) => {
  e.preventDefault();
  //console.log(this.selectedURL);
  //redirecting to AuthProxy
  AuthProxy.opCont(this.selectedURL);
  
}

handleChange= (e:any) => {
  this.selectedURL=e.target.value;
}
  
  render(): React.ReactElement {
    return (
      <React.Fragment>
        <div id="formWrapper">
        <div id="infoBar"></div>
        <div><h2 id="userWarning">You are provisioned for multiple environments.</h2>
        <h3 id="userWarning"> Please select which environment you would like to go to:</h3>
        </div>
        <div id="formBox">
        <form onSubmit={this.handleSubmit} id="form">
        <select name="selectList" id="selectList" onChange={e => this.handleChange(e)}>
        <option value=""></option>
          {((this.props.urls).sort()).map(url => {
            return (
            <option value={url}>{url}</option> 
          )

      })}
        </select>
        <input type="submit" id="submitBtn" />
        </form>
        </div></div>
      </React.Fragment>
    );
  }
}

