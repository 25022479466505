import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as AuthProxy from "./security/AuthProxy";
import { programInformations } from "./utils/OptumPrograms";
import Login from "./Login";

const fileConfig = "./assets/nav/navigation.json";

fetch(fileConfig)
.then((res: any): any => {
  return res.json() as programInformations;
}).then((env: programInformations): any => {
  
  // @ts-ignore
  window["environments"]=env[process.env.REACT_APP_API_ENV];
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  var authType = sessionStorage.getItem("authType");
  // look for resume in URL for SSO 
  const query2=new URLSearchParams(window.location.search);
  const resume=query2.get("resume");
  // look if login page needs to be surpressed 
  //const query3=new URLSearchParams(window.location.search);
  //const prompt=query3.get("prompt");
  const external=new URLSearchParams(window.location.search).get("prompt");
  
  sessionStorage.setItem("origin", window.location.origin);
  document.cookie = "kcdtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

    
  /*if (authType == null || authType === undefined) {
    sessionStorage.setItem("authType", "ohid");
    AuthProxy.loginRedirect();
  }
  */

   if (external){
    // user has come from another application and is already signed in with OHID
    // supress the first sign on screen and kick off OHID call with PKCE
    if (
      (typeof sessionStorage.getItem("sessionId") == "undefined" ||
        sessionStorage.getItem("sessionId") == null) &&
      (code === null || code === "undefined")
    ) {
      sessionStorage.setItem("authType", "ohid");
      AuthProxy.loginRedirect();
    }
  }
 
  
  console.log("external: " + external);
  console.log("prompt: " + prompt);
  console.log("Query: " + query);
  console.log("AUTH TYPE: " + authType);
  console.log("CODE TYPE: " + code);
  console.log("SESSION ID: " + sessionStorage.getItem("sessionId"));
  console.log("CODE TYPE: " + code);


/*  if (
    (typeof sessionStorage.getItem("sessionId") == "undefined" ||
      sessionStorage.getItem("sessionId") == null) &&
    (code === null || code === "undefined")  
    && (authType == null || authType === undefined)

  ) {
    sessionStorage.setItem("authType", "ohid");
    AuthProxy.loginRedirect();
  }
  */
  console.log("AUTH TYPE: " + authType);
  console.log("CODE TYPE: " + code);

  // no session id but a code in URL (already logged into ohid)
  var verifier = sessionStorage.getItem("verifier");
  var msidVerifier = sessionStorage.getItem("msidVerifier");
  var clientID;
  //console.log("AUTH TYPE: " + authType);
  if(authType === "ohid"){
    if (verifier && code) {
      // code from OHID login is found, so send code and verifier to OHID API call  
      sessionStorage.removeItem("logout");
      verifier = sessionStorage.getItem("verifier");
      clientID = env["clientID"];
      var princeID = env["princeID"];;
      if(process.env.REACT_APP_API_ENV === "prod"){
        princeID = env["prodPrinceID"];
      }
      AuthProxy.session(verifier, code, clientID, princeID);
    }
    else if (resume) {
      var decoded=decodeURIComponent(resume);
      var sessionId=decoded.split('/')[2];
      var pass = env["pass"];
      AuthProxy.postPingFed(sessionId,pass);
    }
    else if(!sessionStorage.getItem("sessionId") && !code) {
      // display home login screen
      var splunkAuthProd=env["splunkProd"].toString();
      var splunkAuthNonP=env["splunkNonProd"].toString();
      sessionStorage.setItem("splunkAuth", splunkAuthProd);
      sessionStorage.setItem("splunkAuthNP", splunkAuthNonP);

      return ReactDOM.render(
        <React.StrictMode>
          <Login />
        </React.StrictMode>,
        document.getElementById("root")
      );
   }
  } else if(authType === "msid"){
    if (msidVerifier && code) {
      // code from msid login is found, so send code and verifier to azure API call
      sessionStorage.removeItem("logout");
      msidVerifier = sessionStorage.getItem("msidVerifier");
      clientID = env["azureClientId"];
      if(process.env.REACT_APP_API_ENV === "prod"){
        clientID = env["prodAzureClientId"];
      }
      AuthProxy.msidToken(msidVerifier, code, clientID);
    }
    else if (resume) {
      decoded=decodeURIComponent(resume);
      sessionId=decoded.split('/')[2];
      pass = env["pass"];
      AuthProxy.msidPostPingFed(sessionId,pass);
    }
    else if(!sessionStorage.getItem("sessionId") && !code) {
      // display home login screen
      var splunkAuthProd=env["splunkProd"].toString();
      var splunkAuthNonP=env["splunkNonProd"].toString();
      sessionStorage.setItem("splunkAuth", splunkAuthProd);
      sessionStorage.setItem("splunkAuthNP", splunkAuthNonP);

      return ReactDOM.render(
        <React.StrictMode>
          <Login />
        </React.StrictMode>,
        document.getElementById("root")
      );
   }
    
  } 

  else if(!sessionStorage.getItem("sessionId") && !code) {
    // display home login screen
      var splunkAuthProd=env["splunkProd"].toString();
      var splunkAuthNonP=env["splunkNonProd"].toString();
      sessionStorage.setItem("splunkAuth", splunkAuthProd);
      sessionStorage.setItem("splunkAuthNP", splunkAuthNonP);

    return ReactDOM.render(
      <React.StrictMode>
        <Login />
      </React.StrictMode>,
      document.getElementById("root")
    );
 }

});
