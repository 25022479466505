import React, { FC } from "react";
import "./Login.css";
import * as AuthProxy from "./security/AuthProxy";
import { BrowserRouter as Router } from "react-router-dom";
import { Button, Footer } from "@better-ui/react";
import "@better-ui/styles/optum/optum.css";

interface LoginProps {}

export interface LoginState {
  currentDateTime: String;
}
export default class Login extends React.Component<LoginProps, LoginState> {

  private intervalID: any;

  constructor(props: any){
    super(props);
    this.state = { currentDateTime: Date().toLocaleString()};
  }

  componentDidMount() {
    window.process = { ...window.process } 
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      currentDateTime: new Date().toLocaleString('en-US', { hour12: false })
    });
  }

  ohidLogin(): void {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (
      (typeof sessionStorage.getItem("sessionId") == "undefined" ||
        sessionStorage.getItem("sessionId") == null) &&
      (code === null || code === "undefined")
    ) {
      sessionStorage.setItem("authType", "ohid");
      AuthProxy.loginRedirect();
      return;
    }
  }


  msidLogin() {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (
      (typeof sessionStorage.getItem("sessionId") == "undefined" ||
        sessionStorage.getItem("sessionId") == null) &&
      (code === null || code === "undefined")
    ) {
      sessionStorage.setItem("authType", "msid");
      AuthProxy.msidRedirect();
      return;
    }
  }

  


  render(): React.ReactElement {

    let env = "";

    const DefaultLogo: FC<{theme: string}> =({theme}) =>{
      //apply environment button dynamically based on the environment

      if (window.location.href.includes("local")) { 

        env= "local";

        return <div className="bui-button-footer footer-btn-local" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }

      if (window.location.href.includes("dev")) { 

        env= "dev";

        return <div className="bui-button-footer footer-btn-dev" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("qa") ) { 
        env = "qa";

        return <div className="bui-button-footer footer-btn-qa" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      if (window.location.href.includes("stg") ) { 
        env= "stg";

        return <div className="bui-button-footer footer-btn-stg" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
      }
      else  { 

        env= "prod";

        return <div className="bui-button-footer footer-btn-prod" ><span className="envLabel">{env.toUpperCase()}</span> <span className="date-time">{this.state.currentDateTime}</span></div>
    }
    
  }
    return (
      <React.Fragment>
        <div className="App">
          <div className="infoBar">
          </div>
          <div id="wrapper"> 
            <div className="content">
              <p id="pageHeader">Welcome to RxClaim Web</p>
            
             
              <Router>
                <div id="login-container">
                <div className="text">
                <p>
                  This site allows employers and plan sponsors to manage their
                  members' pharmacy benefits. Please sign in using your MSID/One
                  Healthcare ID credentials.
                </p>
                <p>
                  If you are unable to login using your credentials, please contact
                  your client management/helpdesk representative, or use the
                  forgot password link on the next page.
                </p>
              </div>
                  <div id="login-content">
          
                    
                      <Button onClick={this.ohidLogin} className="bui-button is-primary">
                        One Healthcare ID Login
                      </Button>
                   

                   
                      <Button  onClick={this.msidLogin} className="bui-button is-primary">
                        Employee MSID Login
                      </Button>
                    
                  </div>
                  
                </div>
              </Router>
            </div>
          </div>
          <Footer className="footerContainer" /*logoContent={<DefaultLogo theme={"bui-uhc-theme"}/>}*/ /*linkConfig={{links: [{href:'#', label: <DefaultLogo theme={"bui-uhc-theme"} />, className: "linkStyle"}]}}*/>
          <DefaultLogo theme={"bui-uhc-theme"} ></DefaultLogo>
      </Footer>
        </div>
      </React.Fragment>
    );
  }
}
